import { ListItemContent } from '../../components/display/ListItemContent'
import React from 'react'

type Props = {
  phase: any
}

const formatTime = (time: { h: number; m: number }) => {
  return `${time.h < 10 ? `0${time.h}` : time.h}:${time.m < 10 ? `0${time.m}` : time.m}`
}
export const ProjectPhaseListItem = ({ phase }: Props) => {
  const questionnaires = phase.questionnaires || []
  return (
    <div className="col-12 mb-4">
      <div className="border-bottom bg-white" style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.06)' }}>
        <div className="d-flex align-items-center px-3 pt-3 pb-2 border-bottom">
          <ListItemContent label={phase._id} title={phase.title} style={{ width: '40%' }} />
          <ListItemContent label="duration" title={`${phase.duration} days`} style={{ width: '15%' }} />
          <ListItemContent label="type" className="ps-0 my-3" title={phase.type} style={{ width: '15%' }} />
          <ListItemContent
            label="Short compliance trigger"
            className="ps-0 my-3"
            title={phase.shortCompliance ? phase.shortCompliance + ' days' : '-'}
            style={{ width: '15%' }}
          />
          <ListItemContent
            label="Overall compliance trigger"
            className="ps-0 my-3"
            title={phase.overallCompliance ? phase.overallCompliance + '%' : '-'}
            style={{ width: '15%' }}
          />
        </div>

        <div className="col-12 px-4 mt-3 pt-1 mb-2">
          <p className="fw-bold p-small opacity-75 mb-3" style={{ letterSpacing: 0.3 }}>
            Questionnaires
          </p>

          {questionnaires.length !== 0 ? (
            questionnaires.map((item, index) => {
              return <QuestionnaireListItem item={item} key={index} />
            })
          ) : (
            <NoQuestionnaire to={''} />
          )}
        </div>
      </div>
    </div>
  )
}

type ListProps = { item: any }

const QuestionnaireListItem = ({ item }: ListProps) => {
  const questionnaireTypeLabel = item?.onboarding ? 'Onboarding' : item?.eligibility ? 'Eligibility' : null

  return (
    <div className="row" style={{}}>
      <div className="col-12">
        <div className="bg-light w-100 d-flex rounded-1 border align-items-center px-3 py-4 mb-3">
          <div className=" " style={{ width: '35%' }}>
            <p className="mb-0 p-mini alpha-80 ">Title</p>
            <p className="text-truncate">
              {item?.name &&
                Object.values(item.name).map((name: any, index: number) => {
                  return (
                    <span className="me-1 " key={index}>
                      {name},
                    </span>
                  )
                })}
            </p>
          </div>

          <div className="" style={{ width: '15%' }}>
            <div className="">
              <p className="mb-0 p-mini alpha-80">Recurrence</p>
              <p className="mb-0 fw-bold text-primary ">{item?.recurrence?.type || 'Not set'}</p>
            </div>
          </div>

          <div className="" style={{ width: '20%' }}>
            <div className="">
              <p className="mb-0 p-mini alpha-80">Offset</p>
              <p className="mb-0 fw-bold text-primary">Day {item?.recurrence?.offset} of phase</p>
            </div>
          </div>

          <div className="" style={{ width: '10%' }}>
            <div className="">
              <p className="mb-0 p-mini alpha-80">Window</p>
              <p className="mb-0 fw-bold text-primary ">
                {formatTime(item?.recurrence?.startAt)}-{formatTime(item?.recurrence?.endAt)}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center" style={{ width: '20%' }}>
            {questionnaireTypeLabel && (
              <div
                className="rounded-pill d-inline-block px-3 py-1 mt-1 border "
                style={{ background: '#f1f1f1', minWidth: 100 }}
              >
                <p className="text-center" style={{ fontSize: '0.75rem', color: '#818181' }}>
                  {questionnaireTypeLabel}
                </p>
              </div>
            )}
          </div>
          {/*<div className="d-flex  justify-content-end" style={{ width: '10%' }}>
            <Link to={`questionnaire/${item._id}`}>
              <Button className="px-4">View</Button>
            </Link>
          </div>*/}
        </div>
      </div>
    </div>
  )
}

const NoQuestionnaire = ({ to }) => {
  return (
    <div className="col-12 px-4 mb-3">
      <div className="row pb-2 border rounded">
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex justify-content-center align-items-center">
            <p className="me-2 opacity-75">No questionnaires in this phase</p>
          </div>
        </div>
      </div>
    </div>
  )
}
